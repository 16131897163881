#single {
  .brandContainer {
    .swiper {
      margin-bottom: 30px;
      @include media-breakpoint-up(sm) {
        margin-bottom: 80px;
      }
      
    }
    .body {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 50px;
      @include media-breakpoint-up(sm) {
        gap: 80px;
      }
      .text {
        @include media-breakpoint-up(sm) {
          width: 50%;
        }
        .title {
          margin-bottom: 30px;
          font-size: 25px;
          font-weight: bold;
          @include media-breakpoint-up(sm) {
            margin-bottom: 50px;
            font-size: 35px;
          }
        }
        .item {
          margin-bottom: 10px;
          font-size: 15px;
          font-weight: bold;
        }
        .cont {
          margin: 0;
          font-size: 15px;
        }
      }
      .moreBtn {
      }
    }
  }
}
