.faq {
  .faqSingle {
    border-bottom: solid 1px #d9d9d9;
    &:first-child {
      .header {
        padding: 0 0 10px;
      }
    }
    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: $body-color;
      text-decoration: none;
      width: 100%;
      padding: 20px 0 10px;
      gap: 15px;
      @include media-breakpoint-up(sm) {
        padding: 20px 0 10px;
        gap: 24px;
      }
      .icon {
        font-size: 20px;
        font-weight: bold;
        font-family: $font-family-en;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: $body-color;
        color: $white;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .title {
        flex: 1;
        font-size: 16px;
        font-weight: bold;
        margin: 0;
      }
    }
    .body {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      color: $body-color;
      text-decoration: none;
      width: 100%;
      padding: 0 0 20px;
      gap: 15px;
      @include media-breakpoint-up(sm) {
        padding: 0 0 20px;
        gap: 24px;
      }
      .icon {
        font-size: 20px;
        font-weight: bold;
        font-family: $font-family-en;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: $primary;
        color: $white;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .cont {
        line-height: 2;
        flex: 1;
        p {
          margin: 0;
        }
      }
    }
  }
}
