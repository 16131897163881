#contactArea {
  background-image: url(../../../bijousyoukai/assets/img/bg-contact.png);
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  background-size: cover;
  .bg-color {
    background-color: rgba(51, 51, 51, 0.6);
    height: 100%;
    padding: 40px 6vw;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    @include media-breakpoint-up(sm) {
      width: 100%;
      padding: 80px 10vw;
    }
    .midashi1 {
    }
    .comment {
      font-size: 16px;
      font-weight: bold;
      color: $white;
      margin-bottom: 30px;
      @include media-breakpoint-up(sm) {
        margin-bottom: 50px;
      }
      p {
        margin: 0;
      }
    }
    .box-group {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 50px;
      width: 100%;
      @include media-breakpoint-up(sm) {
        flex-direction: row;
        justify-content: space-between;
      }
      .contactbox {
        background-color: rgba(255, 255, 255, 0.7);
        margin: 0 auto;
        width: 100%;
        padding: 25px;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        @include media-breakpoint-up(sm) {
          padding: 40px;
        }
      }
      .left {
        .header {
          font-size: 16px;
          font-weight: bold;
          margin-bottom: 15px;
        }
        .body {
          margin-bottom: 5px;
          color: #669800;
          font-size: 25px;
          font-weight: bold;
          line-height: normal;
          @include media-breakpoint-up(lg) {
            font-size: 32px;
          }
        }
        .footer {
          margin: 0;
          font-size: 15px;
          font-weight: bold;
        }
      }
      .right {
        .title {
          font-size: 16px;
          font-weight: bold;
          margin-bottom: 20px;
        }
        .contactBtn {
          margin: 0 auto;
        }
      }
    }
  }
}
