.toggleMenu {
  position: fixed;
  right: 0;
  top: 0;
  width: auto;
  height: auto;
  z-index: $zindex-fixed;
  min-width: $headerHeight;
  min-height: $headerHeight;
  font-weight: bold;
  @include media-breakpoint-up(lg) {
    display: none;
  }
  .toggleMenuBtn {
    position: absolute;
    z-index: 2;
    right: 0;
    top: 0;
    width: $headerHeight;
    height: $headerHeight;
    display: flex;
    gap: 8%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: $primary;
    transition: $transition-base;
    border-radius: 0 0 0 $border-radius;
    @include media-breakpoint-up(lg) {
      width: $headerHeightSM;
      height: $headerHeightSM;
    }
    cursor: pointer;
    .bar,
    &::before,
    &::after {
      width: 36%;
      height: 1px;
      background-color: $white;
      content: "";
      transition: $transition-base;
    }
    &::after {
      transform: scaleX(60%);
      transform-origin: left;
    }
    &:hover {
      &::after {
        transform: scaleX(100%);
      }
    }
    &.active {
      .bar {
        opacity: 0;
        display: none;
        position: absolute;
      }
      &::before {
        position: absolute;
        top: 50%;
        left: 32%;
        transform: rotate(45deg);
        transform-origin: center;
      }
      &::after {
        position: absolute;
        top: 50%;
        right: 32%;
        transform: rotate(-45deg);
        transform-origin: center;
      }
    }
  }
  .cont {
    padding-top: $headerHeight;
    background-color: $white;
    color: $white;
    position: relative;
    z-index: 1;
    width: 600px;
    height: 100vh;
    max-width: 100vw;
    overflow-y: auto;
    @include media-breakpoint-up(sm) {
      padding-top: $headerHeightSM;
    }
    .gnav {
      margin-bottom: 40px;
      padding: 0;
      list-style-type: none;
      @include media-breakpoint-up(sm) {
        margin: 0;
      }
      > li {
        > a {
          color: $body-color;
          text-decoration: none;
          display: flex;
          padding: 1rem 2rem;
        }
      }
    }
    .box {
      margin: 0 6vw;
      a {
        padding: 3vw 0;
        background-color: $primary;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        font-weight: bold;
        color: $white;
        font-size: 15px;
        gap: 5px;
        transition: $transition-base;
      }
      &:hover {
        background-color: $body-color;
      }
      .icon {
        img {
          height: 100%;
          width: 100%;
        }
      }
    }
  }
}
