#contact {
  .midashi1 {
  }
  .comment {
    font-size: 16px;
    margin-bottom: 50px;
    @include media-breakpoint-up(sm) {
      text-align: center;
      margin-bottom: 80px;
    }
  }
  .body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 50px;
    @include media-breakpoint-up(sm) {
      gap: 100px;
    }
    .formTable {
    }
    .contactBtn {
    }
  }
}
