.moreBtn {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
  p {
    color: $primary;
    margin: 0;
    font-size: 15px;
    font-weight: bold;
    margin-right: 20px;
    transition: $transition-base;
  }
  div {
    width: 30px;
    height: 30px;
    background-color: $primary;
    border-radius: 50%;
    text-align: center;
    transition: $transition-base;
    @include media-breakpoint-up(sm) {
      width: 40px;
      height: 40px;
    }
    i {
      line-height: 30px;
      color: $white;
      @include media-breakpoint-up(sm) {
        line-height: 40px;
      }
    }
  }
  &:hover {
    p {
      color: $body-color;
    }
    div {
      background-color: $body-color;
    }
  }
  &.back {
    p {
      margin-left: 20px;
    }
  }
}
.entryBtn01 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  text-align: center;
  padding: 20px;
  background: linear-gradient(to right, #f09819, #edde5d);
  text-decoration: none;
  transition: $transition-base;
  @include media-breakpoint-up(sm) {
    padding: 10px;
  }
  @include media-breakpoint-up(lg) {
    padding: 20px;
  }
  p {
    color: $white;
    font-size: 15px;
    margin: 0 0 15px;
    white-space: nowrap;
  }
  i {
    color: $white;
  }
  &:hover {
    background: $body-color;
  }
}
.entryBtn02 {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px;
  background: linear-gradient(to right, #ff8008, #ffc837);
  text-decoration: none;
  width: 250px;
  transition: $transition-base;
  @include media-breakpoint-up(sm) {
    padding: 30px;
    width: 280px;
  }
  p {
    position: relative;
    color: $white;
    font-size: 15px;
    margin: 0;
    white-space: nowrap;
    @include media-breakpoint-up(sm) {
      font-size: 18px;
    }
  }
  i {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    color: $white;
  }
  &:hover {
    background: $body-color;
  }
}
.contactBtn {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px;
  background: $primary;
  text-decoration: none;
  width: 100%;
  transition: $transition-base;
  @include media-breakpoint-up(lg) {
    width: 280px;
  }
  p {
    position: relative;
    color: $white;
    font-size: 15px;
    margin: 0;
    white-space: nowrap;
  }
  i {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    color: $white;
  }
  &:hover {
    background: $body-color;
  }
}
