.midashi1 {
  margin-bottom: 25px;
  @include media-breakpoint-up(sm) {
    margin-bottom: 50px;
  }
  .en {
    color: $primary;
    font-size: 10px;
    font-weight: bold;
    margin: 0;
    @include media-breakpoint-up(sm) {
      font-size: 15px;
    }
  }
  .ja {
    color: $body-color;
    font-size: 25px;
    font-weight: bold;
    margin: 0;
    @include media-breakpoint-up(sm) {
      font-size: 35px;
    }
  }
  &.white {
    .en {
      color: $white;
    }
    .ja {
      color: $white;
    }
  }
}
