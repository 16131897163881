#service {
  padding-left: 6vw;
  padding-right: 6vw;
  .midashi1 {
  }
  .serviceBody {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 50px;
    @include media-breakpoint-up(sm) {
      gap: 150px;
    }
    .serviceBox {
      .header {
        position: relative;
        margin: 0 6vw;
        .img {
          position: relative;
          img {
            width: 100%;
          }
        }
        .bg-white {
          position: absolute;
          width: 50vw;
          padding: 10px 0;
          padding-left: 15px;
          top: 50%;
          transform: translateY(-50%);
          right: 0;
          @include media-breakpoint-up(sm) {
            width: 30vw;
          }
          @include media-breakpoint-up(lg) {
            width: 25vw;
            padding: 18px 0;
            padding-left: 30px;
          }
          .number {
            margin: 0;
            color: $primary;
            opacity: 0.6;
            font-size: 18px;
            font-weight: bold;
            font-family: "DIN Alternate";
          }
          .title {
            color: $body-color;
            font-size: 20px;
            font-weight: bold;
            margin: 0;
            @include media-breakpoint-up(sm) {
              font-size: 24px;
            }
          }
        }
      }
      .box-group {
        padding: 100px 6vw 50px;
        margin-top: -70px;
        @include media-breakpoint-up(lg) {
          padding: 200px 6vw 80px;
          margin-top: -150px;
        }
        .box {
          background-color: $white;
          padding: 30px;
          height: 100%;
          @include media-breakpoint-up(sm) {
            padding: 30px 4vw;
          }
          .img {
            position: relative;
            width: 100%;
            overflow: hidden;
            margin-bottom: 5px;
            &::before {
              content: "";
              width: 100%;
              padding-top: 75%; //高さの比率 ÷ 幅の比率 × 100
              display: block;
            }
            img {
              position: absolute;
              top: 0;
              left: 0;
              bottom: 0;
              right: 0;
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
          .text {
            .title {
              text-align: center;
              margin-bottom: 15px;
              font-weight: bold;
              font-size: 18px;
              @include media-breakpoint-up(sm) {
                font-size: 20px;
              }
            }
            .item {
              margin: 0;
            }
          }
        }
      }
    }
  }
}
