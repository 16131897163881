.gmap {
  width: 100%;
  height: 100%;
  overflow: hidden;
  iframe {
    border: 0;
    width: 100%;
    height: 170%;
      margin-top: -70px;
    @include media-breakpoint-up(sm) {
      margin-top: -170px;
    }
  }
}
