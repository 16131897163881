#heroArea {
  position: relative;
  text-align: end;
  height: calc(100% - 80px);
  @include media-breakpoint-up(lg) {
    height: calc(100% - 100px);
  }
  .img {
    width: 100vw;
    @include media-breakpoint-up(sm) {
      width: 100vw;
      margin: 0 0 0 auto;
    }
    @include media-breakpoint-up(lg) {
      width: 75vw;
    }
    img {
      width: 100%;
    }
  }
  .cont {
    @include media-breakpoint-up(sm) {
      position: absolute;
      width: 45vw;
      top: 50%;
      left: 2vw;
      transform: translateY(-50%);
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 10px;
    }
    @include media-breakpoint-up(lg) {
      width: auto;
      gap: 38px;
      left: 6vw;
    }
    .title {
      position: absolute;
      top: 30%;
      left: 50%;
      transform: translateX(-50%);
      width: 70%;
      @include media-breakpoint-up(sm) {
        width: auto;
        position: static;
        transform: none;
      }
      h2 {
        background-color: $white;
        font-size: 30px;
        font-weight: bold;
        text-align: center;
        line-height: 1.8em;
        margin: 0;
        padding: 20px;
        @include media-breakpoint-up(sm) {
          text-align: left;
          padding: 10px;
        }
        @include media-breakpoint-up(xl) {
          font-size: 48px;
          padding: 30px 20px;
        }
      }
    }
    .entry {
      display: flex;
      flex-direction: row;
      justify-content: center;
      @include media-breakpoint-up(lg) {
        width: 26vw;
      }
      .text {
        flex-basis: 70%;
        background-color: $white;
        padding: 20px;
        font-weight: bold;
        text-align: left;
        @include media-breakpoint-up(sm) {
          padding: 10px 20px;
        }
        @include media-breakpoint-up(lg) {
          padding: 20px;
        }
        .entry01 {
          font-size: 20px;
          margin-bottom: 10px;
        }
        .entry02 {
          font-size: 15px;
          margin: 0;
        }
      }
      .entryBtn01 {
      }
    }
  }
  .anniversary {
    position: absolute;
    bottom: 150px;
    right: 0;
    max-width: 30%;
    @include media-breakpoint-up(sm) {
      width: auto;
      bottom: 0;
    }
    img {
      max-width: 100%;
    }
  }
}
