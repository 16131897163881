#companyArea {
  .brandContainer {
    .midashi1 {
    }
    .row {
      @include media-breakpoint-up(sm) {
        justify-content: space-between;
      }
    }
  }
}
