#footer {
  background-color: $body-color;
  padding: 50px 0 20px;
  .brandContainer {
    .group01 {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 20px;
      margin-bottom: 50px;
      @include media-breakpoint-up(sm) {
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 100px;
        gap: 0;
      }
      .left {
        .logo {
          a {
            img {
            }
          }
        }
      }
      .right {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 20px;
        @include media-breakpoint-up(sm) {
          flex-direction: row;
          gap: 90px;
        }
        .gnav {
          margin: 0;
          padding: 0;
          list-style: none;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          gap: 20px;
          @include media-breakpoint-up(sm) {
            gap: 30px;
          }
          a {
            color: $white;
            text-decoration: none;
            font-weight: bold;
            transition: $transition-base;
            &:hover {
              color: $primary;
            }
          }
          .gnav-sub {
            margin: 10px 0 0 10px;
            padding: 0;
            list-style: none;
            li {
              .sub {
                color: $white;
                text-decoration: none;
                font-size: 12px;
                font-weight: normal;
                transition: $transition-base;
                align-items: center;
                justify-content: flex-start;
                gap: 8px;
                display: flex;
                margin-bottom: 5px;
                @include media-breakpoint-up(sm) {
                  margin-bottom: 10px;
                }
                &:hover {
                  color: $primary;
                }
              }
              &:last-child {
                .sub {
                  margin: 0;
                }
              }
            }
          }
        }
      }
    }
    .group02 {
      color: $white;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 10px;
      font-size: 10px;
      margin: 0;
      @include media-breakpoint-up(sm) {
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-end;
      }
      .privacy {
        margin: 0;
        a {
          color: $white;
          text-decoration: none;
          transition: $transition-base;
          &:hover {
            color: $primary;
          }
        }
      }
      .copyright {
        margin: 0;
      }
      .topBtn {
        margin: 0 0 0 auto;
        transition: $transition-base;
        @include media-breakpoint-up(sm) {
          position: relative;
          top: 0;
          margin: 0;
        }
        &:hover {
          top: -3px;
        }
      }
    }
  }
}
