.formTable {
  font-size: 15px;
  width: 100%;
  dl {
    display: block;
    margin: 0;
    padding: 0;
    dt {
      display: block;
      text-align: left;
      padding: 15px 0;
      margin: 0;
      .title {
        p {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 10px;
          font-weight: normal;
          margin: 0;
          @include media-breakpoint-up(sm) {
            justify-content: space-between;
          }
        }
      }
      .badge {
        padding: 5px 8px;
        border-radius: 0;
        font-weight: normal;
        font-size: 13px;
      }
    }
    dd {
      display: block;
      text-align: left;
      padding: 0 0 15px;
      margin: 0;
      p {
        margin: 0;
        .wpcf7-form-control.wpcf7-text.wpcf7-tel.wpcf7-validates-as-tel,
        input.wpcf7-form-control.wpcf7-text.wpcf7-validates-as-required,
        textarea.wpcf7-form-control.wpcf7-textarea,
        .wpcf7-select.wpcf7-validates-as-required {
          width: 100%;
          margin: 0;
          background-color: #f5f5f5;
          border: none;
          border-radius: 0;
          padding: 10px;
        }
      }
    }
  }
  @include media-breakpoint-up(sm) {
    display: table;
    dl {
      display: table-row;
      dt {
        display: table-cell;
        padding: 35px 0;
        width: 17%;
        white-space: nowrap;
        vertical-align: top;
      }
      dd {
        display: table-cell;
        padding: 25px 0;
        padding-left: 4vw;
        .wpcf7-form-control.wpcf7-text.wpcf7-tel.wpcf7-validates-as-tel,
        input.wpcf7-form-control.wpcf7-text.wpcf7-validates-as-required,
        textarea.wpcf7-form-control.wpcf7-textarea,
        .wpcf7-select.wpcf7-validates-as-required {
          padding: 15px;
        }
      }
    }
  }
}

input.wpcf7-submit {
  color: $white;
  display: block;
  padding: 20px 50px;
  margin: 50px auto 0;
  border-radius: 0;
  background: $primary;
  font-size: 12px;
  font-weight: 400;
  width: 250px;
  transition: $transition-base;
  border: none;
  @include media-breakpoint-up(sm) {
    width: 280px;
    padding: 23px 100px;
    margin: 100px auto 0;
    font-size: 15px;
  }
}
input.wpcf7-submit:hover {
  background: $body-color;
}

.btn-submit {
  width: 100%;
  text-align: center;
}
