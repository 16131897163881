#serviceArea {
  padding-bottom: 10px;
  @include media-breakpoint-up(sm) {
    padding-bottom: 50px;
  }
  .brandContainer {
    .serviceHeader {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      .midashi1 {
      }
      .moreBtn {
      }
    }
    .serviceBody {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      gap: 30px;
      @include media-breakpoint-up(sm) {
        gap: 150px;
      }
      @include media-breakpoint-up(lg) {
        gap: 60px;
      }
      .serviceBox {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;
        gap: 10px;
        @include media-breakpoint-up(sm) {
          position: relative;
          gap: 0;
        }
        .img {
          @include media-breakpoint-up(sm) {
            width: 80%;
          }
          img {
            width: 100%;
          }
        }
        .bg-white {
          @include media-breakpoint-up(sm) {
            position: absolute;
            padding: 20px 40px;
            top: 40%;
            right: 0;
          }
          @include media-breakpoint-up(lg) {
            padding: 40px 80px;
            top: 50%;
            transform: translateY(-50%);
          }
          .number {
            margin: 0;
            color: $primary;
            opacity: 0.6;
            font-size: 18px;
            font-weight: bold;
            font-family: "DIN Alternate";
          }
          .title {
            margin-bottom: 20px;
            color: $body-color;
            font-size: 20px;
            font-weight: bold;
            @include media-breakpoint-up(sm) {
              margin-bottom: 30px;
              font-size: 24px;
            }
          }
          .list {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 25px;
            @include media-breakpoint-up(sm) {
              gap: 40px;
            }
            ul {
              margin: 0;
              padding-left: 15px;
              li {
                color: #c1d39d;
                white-space: nowrap;
                span {
                  color: $body-color;
                }
              }
            }
          }
        }
        &.reverse {
          @include media-breakpoint-up(sm) {
            flex-direction: row-reverse;
          }
          .bg-white {
            @include media-breakpoint-up(sm) {
              position: absolute;
              left: 0;
              right: auto;
            }
          }
        }
      }
    }
  }
}
