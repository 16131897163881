@import "custom";
@import "~bootstrap/scss/bootstrap";

html {
  font-size: 15px;
  font-feature-settings: "pwid";
  letter-spacing: 0.1em;
}
.componentTitle {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  opacity: 0.25;
  z-index: $zindex-fixed;
  padding: 15px;
  font-size: 10px;
  transition: $transition-base;
  &:hover {
    opacity: 1;
  }
}
@mixin fontSize($font_size: 10) {
  font-size: calc($font_size / 390 * 100vw);
}
@mixin fontSizeSM($font_size: 10) {
  font-size: calc($font_size / 820 * 100vw);
}
@mixin fontSizeLG($font_size: 10) {
  font-size: calc($font_size / 1366 * 100vw);
}
img{
  max-width: 100%
}
@import "components/header";
@import "components/toggleMenu";
@import "components/layout";
// @import "components/summary";
// @import "components/outline";
// @import "components/digest";
// @import "components/digestCover";
// @import "components/article";
// @import "components/sectionHeader";
// @import "components/secondHeader";
// @import "components/coverBgImg";
// @import "components/sideMenu";
// @import "components/tagCloud";
// @import "components/narrowThumbnailLink";
// @import "components/imgList";
@import "components/carousels";
@import "components/formTable";
@import "components/brandTable";
@import "components/flow";
@import "components/faq";
// @import "components/newsList";
// @import "components/newsBar";
// @import "components/newsArchive";
// @import "components/catchCopyBar";
// @import "components/catchCopyBorder";
// @import "components/anchorLinks";
// @import "components/outlineCard";
@import "components/midashi1";
// @import "components/midashi2";
// @import "components/midashi3";
@import "components/footer";
@import "components/contactArea";
@import "components/heroArea";
@import "components/conceptArea";
@import "components/btn";
@import "components/serviceArea";
@import "components/productArea";
@import "components/flowArea";
@import "components/faqArea";
@import "components/companyArea";
@import "components/gmap";
@import "components/entryArea";
@import "components/manufacturer";
@import "components/example";
@import "components/pagination";
@import "components/single";
@import "components/service";
@import "components/contact";
@import "components/animation";
@import "components/vif";


