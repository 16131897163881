#entryArea {
  padding: 0 0 60px;
  @include media-breakpoint-up(sm) {
    padding: 0 0 90px;
  }
  @include media-breakpoint-up(lg) {
    padding: 0 0 150px;
  }
  .img {
    width: 100%;
    @include media-breakpoint-up(sm) {
      margin-right: 6vw;
    }
    @include media-breakpoint-up(lg) {
      margin-right: 13vw;
    }
    img {
    }
  }
  .bg-orange {
    background-color: #fff5df;
    padding: 30px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    @include media-breakpoint-up(sm) {
      margin-left: 6vw;
      margin-top: -80px;
      padding: 100px 6vw 50px 50px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;
    }
    @include media-breakpoint-up(lg) {
      margin-left: 13vw;
      margin-top: -60px;
      padding: 100px 13vw 50px 70px;
    }
    .left {
      .midashi1 {
        @include media-breakpoint-up(sm) {
          margin-bottom: 30px;
        }
      }
      .title {
        font-weight: bold;
        font-size: 20px;
        margin-bottom: 20px;
        @include media-breakpoint-up(sm) {
          font-size: 28px;
        }
      }
      .text {
        margin-bottom: 30px;
        @include media-breakpoint-up(sm) {
          margin: 0;
        }
      }
    }
    .right {
      margin: 0 auto;
      @include media-breakpoint-up(sm) {
        margin-right: 0;
      }
      .entryBtn {
      }
    }
  }
}
