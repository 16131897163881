#productArea {
  padding-top: 80px;
  @include media-breakpoint-up(sm) {
    padding-top: 100px;
  }
  .headerImg {
    background-image: url(../../../bijousyoukai/assets/img/bg-product-sm.png);
    background-repeat: no-repeat;
    width: 100%;
    background-size: cover;
    height: 300px;
    @include media-breakpoint-up(sm) {
      background-image: url(../../../bijousyoukai/assets/img/bg-product.png);
      height: 380px;
    }
    .bg-color {
      background-color: rgba($color: #4a513c, $alpha: 0.6);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
      text-align: center;
      .midashi1 {
        margin-top: -50px;
      }
      .comment {
        font-size: 16px;
        color: $white;
      }
    }
  }
  .bg-gray {
    position: relative;
    padding: 0 6vw;
    padding-bottom: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @include media-breakpoint-up(lg) {
      padding: 0 13vw;

      padding-bottom: 100px;
    }
    .box-group {
      position: relative;
      width: 100%;
      top: -80px;
      @include media-breakpoint-up(lg) {
        top: -100px;
      }
      .box {
        background-color: $white;
        padding: 30px 15px;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        gap: 25px;
        @include media-breakpoint-up(sm) {
          padding: 40px 15px;
          flex-direction: row;
        }
        .img {
          position: relative;
          width: 100%;
          overflow: hidden;
          @include media-breakpoint-up(sm) {
            flex-basis: 50%;
          }
          &::before {
            content: "";
            width: 100%;
            padding-top: 75%; //高さの比率 ÷ 幅の比率 × 100
            display: block;
          }
          img {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .text {
          border-bottom: solid 1px #d9d9d9;
          width: 100%;
          overflow-wrap: break-word;
          text-align: center;
          overflow: hidden;
          @include media-breakpoint-up(sm) {
            flex: 1;
          }
          p {
            margin: 0;
            font-size: 15px;
            font-weight: bold;
            padding-bottom: 5px;
            @include media-breakpoint-up(sm) {
              font-size: 18px;
            }
          }
        }
      }
    }
    .moreBtn {
      margin-top: -30px;
      @include media-breakpoint-up(sm) {
        margin-top: -50px;
      }
    }
  }
}
