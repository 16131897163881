#manufacturer {
  .headerImg {
    background-image: url(../../../bijousyoukai/assets/img/bg-manufacturer.png);
    background-repeat: no-repeat;
    width: 100%;
    background-size: cover;
    height: 300px;
    @include media-breakpoint-up(sm) {
      height: 380px;
    }
    .bg-color {
      background-color: rgba($color: #4a513c, $alpha: 0.6);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
      text-align: center;
      .midashi1 {
        margin-top: -50px;
      }
      .comment {
        font-size: 16px;
        color: $white;
        font-weight: bold;
      }
    }
  }
  .bg-gray {
    position: relative;
    padding: 0 6vw;
    padding-bottom: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @include media-breakpoint-up(lg) {
      padding: 0 13vw;
      padding-bottom: 100px;
    }
    .box-group {
      position: relative;
      width: 100%;
      top: -80px;
      @include media-breakpoint-up(lg) {
        top: -100px;
      }
      .box {
        position: relative;
        background-color: $white;
        padding: 30px 15px;
        width: 100%;
        height: 100%;
        @include media-breakpoint-up(sm) {
          padding: 40px 15px;
        }
        .boxLink {
          text-decoration: none;
          color: $body-color;
          transition: $transition-base;
          .cont {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            gap: 25px;
            text-decoration: none;
            @include media-breakpoint-up(sm) {
              flex-direction: row;
            }
            .img {
              position: relative;
              width: 100%;
              overflow: hidden;
              @include media-breakpoint-up(sm) {
                flex-basis: 50%;
              }
              &::before {
                content: "";
                width: 100%;
                padding-top: 75%; //高さの比率 ÷ 幅の比率 × 100
                display: block;
              }
              img {
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                width: 100%;
                height: 100%;
                object-fit: contain;
              }
            }
            .text {
              width: 100%;
              overflow-wrap: break-word;
              overflow: hidden;
              // display: flex;
              // flex-direction: column;
              @include media-breakpoint-up(sm) {
                flex: 1;
              }
              .title {
                margin: 0;
                font-size: 15px;
                font-weight: bold;
                padding-bottom: 5px;
                margin-bottom: 10px;
                border-bottom: solid 1px #d9d9d9;
                text-align: center;
              }
              // .item {
              //   margin: 0;
              //   font-size: 15px;
              // }
            }
          }
          i {
            position: absolute;
            bottom: 5px;
            right: 5px;
            @include media-breakpoint-up(sm) {
              bottom: 15px;
              right: 15px;
            }
          }
          &:hover {
            opacity: 0.5;
          }
        }
      }
    }
  }
}
