#flowArea {
  padding-bottom: 10px;
  @include media-breakpoint-up(sm) {
    padding-bottom: 50px;
  }
  .midashi1 {
  }
  .comment {
    font-size: 16px;
    margin-bottom: 30px;
    padding: 0 6vw;
    @include media-breakpoint-up(sm) {
      margin-bottom: 50px;
      text-align: center;
      line-height: 2em;
    }
  }
  .bg-gray {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
    margin: 0 6vw;
    padding: 10vw;
    @include media-breakpoint-up(sm) {
      gap: 50px;
      padding: 50px 20px;
    }
    @include media-breakpoint-up(lg) {
      padding: 80px;
    }
    .img {
      img {
      }
    }
    .moreBtn {
    }
  }
}
