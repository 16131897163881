#conceptArea {
  position: relative;
  .backtext {
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: Grantha Sangam MN;
    color: $white;
    opacity: 0.5;
    font-size: 50px;
    font-weight: bold;
    text-align: center;
    width: 100%;
    @include media-breakpoint-up(lg) {
      top: 70%;
      font-size: 100px;
    }
  }
  .toptext {
    position: relative;
    padding: 50px 0;
    z-index: 1;
    .title {
      p {
        position: relative;
        padding-left: 6vw;
        font-size: 15px;
        font-weight: bold;
        margin-bottom: 30px;
        @include media-breakpoint-up(lg) {
          padding-left: 15vw;
        }
        &::before {
          content: "";
          display: block;
          width: 4vw;
          height: 2px;
          background-color: $primary;
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          @include media-breakpoint-up(lg) {
            width: 14vw;
          }
        }
      }
    }
    .cont {
      padding: 0 6vw;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 20px;
      @include media-breakpoint-up(sm) {
        flex-direction: row;
        justify-content: center;
        align-items: center;
      }
      @include media-breakpoint-up(lg) {
        padding: 0 15vw;
        gap: 50px;
      }
      h3 {
        font-size: 25px;
        font-weight: bold;
        margin: 0;
        @include media-breakpoint-up(sm) {
          flex-basis: 30%;
        }
        @include media-breakpoint-up(lg) {
          font-size: 40px;
        }
      }
      p {
        font-size: 15px;
        font-weight: bold;
        margin: 0;
        line-height: 2em;
        @include media-breakpoint-up(sm) {
          flex: 1;
        }
      }
    }
  }
}
