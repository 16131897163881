#example {
  .headerImg {
    background-image: url(../../../bijousyoukai/assets/img/bg-example.png);
    background-repeat: no-repeat;
    width: 100%;
    background-size: cover;
    height: 300px;
    @include media-breakpoint-up(sm) {
      height: 380px;
    }
    .bg-color {
      background-color: rgba($color: #4a513c, $alpha: 0.3);
      height: 100%;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .midashi1 {
        margin-top: -50px;
      }
    }
  }
  .bg-gray {
    padding: 0 6vw;
    padding-bottom: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @include media-breakpoint-up(lg) {
      padding: 0 13vw;
      padding-bottom: 100px;
    }
    .box-group {
      position: relative;
      width: 100%;
      top: -80px;
      @include media-breakpoint-up(lg) {
        top: -100px;
      }
      .box {
        width: 100%;
        height: 100%;
        .boxLink {
          text-decoration: none;
          color: $body-color;
          .cont {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: 100%;
            .img {
              position: relative;
              width: 100%;
              @include media-breakpoint-up(sm) {
                flex-basis: 50%;
              }
              &::before {
                content: "";
                width: 100%;
                padding-top: 75%; //高さの比率 ÷ 幅の比率 × 100
                display: block;
              }
              img {
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }
            .text {
              position: relative;
              width: 85%;
              height: 100%;
              overflow-wrap: break-word;
              background-color: $white;
              margin-top: -20px;
              padding: 15px 10vw;
              text-align: center;
              transition: $transition-base;
              @include media-breakpoint-up(sm) {
                padding: 15px 5vw;
              }
              @include media-breakpoint-up(lg) {
                padding: 15px 3vw;
              }
              .title {
                margin: 0;
                font-size: 20px;
                font-weight: bold;
                padding-bottom: 5px;
                margin-bottom: 10px;
                border-bottom: solid 1px #d9d9d9;
                @include media-breakpoint-up(sm) {
                  font-size: 20px;
                }
              }
              .item {
                margin: 0;
                font-size: 15px;
              }
              .arrow {
                position: absolute;
                bottom: 5px;
                right: 5px;
                width: 25px;
                height: 25px;
                background-color: $body-color;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                transition: $transition-base;
                @include media-breakpoint-up(sm) {
                  bottom: 15px;
                  right: 15px;
                }
                i {
                  color: $white;
                }
              }
              &:hover {
                color: $primary;
                .arrow {
                  background-color: $primary;
                }
              }
            }
          }
        }
      }
    }
  }
}
