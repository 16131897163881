#faqArea {
  padding-top: 80px;
  @include media-breakpoint-up(sm) {
    padding-top: 100px;
  }
  .brandContainer {
    .faqgroup {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      @include media-breakpoint-up(sm) {
        flex-direction: row;
        justify-content: space-between;
        gap: 5vw;
      }
      .miadshi1 {
        @include media-breakpoint-up(sm) {
          flex-basis: 30%;
        }
      }
      .faq {
        @include media-breakpoint-up(sm) {
          flex: 1;
        }
      }
    }
  }
}
