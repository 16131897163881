$headerHeight: 80px;
$headerHeightSM: 100px;
#header {
  position: sticky;
  width: 100%;
  height: $headerHeight;
  top: 0;
  z-index: $zindex-sticky;
  transition: $transition-base;
  .group {
    background-color: $white;
    .logo {
      padding-left: 2vw;
      height: $headerHeight;
      line-height: $headerHeight;
      margin: 0;
      width: 50%;
      img{
        max-width: 100%;
      }
    }
    .gnav {
      display: none;
    }
    .box {
      display: none;
    }
  }
  @include media-breakpoint-up(lg) {
    height: $headerHeight;
    display: block;
    @include media-breakpoint-up(xl) {
      height: $headerHeightSM;
    }
    .group {
      display: grid;
      grid-template:
        "logo gnav ... box" #{$headerHeight}
        / 20vw auto 2vw 80px;
      align-items: center;
      @include media-breakpoint-up(xl) {
        grid-template:
          "logo gnav ... box" #{$headerHeightSM}
          / 20vw auto 5vw 100px;
      }
      .logo {
        grid-area: logo;
        width: 100%;
        a {
          transition: $transition-base;
          &:hover {
            color: $black;
          }
          img {
            width: 100%;
          }
        }
      }
      .gnav {
        grid-area: gnav;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        gap: 1.5em;
        list-style: none;
        margin: 0;
        font-size: 15px;
        font-weight: bold;
        @include media-breakpoint-up(lg) {
          font-size: min(calc(13 / 992 * 100vw), 13px);
        }
        @include media-breakpoint-up(xl) {
          gap: 2.5em;
          font-size: min(calc(15 / 1440 * 100vw), 15px);
        }
        li {
          a {
            color: $primary;
            text-decoration: none;
            padding-bottom: 8px;
            transition: $transition-base;
            &:hover {
              color: $black;
            }
          }
        }
      }
      .box {
        grid-area: box;
        width: 80px;
        height: 80px;
        background-color: $primary;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        color: $white;
        font-size: 12px;
        gap: 5px;
        transition: $transition-base;
        text-decoration: none;
        list-style: none;
        @include media-breakpoint-up(xl) {
          width: 100px;
          height: 100px;
        }
        &:hover {
          background-color: $body-color;
        }
        .icon {
          img {
            height: 100%;
            width: 100%;
          }
        }
      }
    }
  }
  &.scrolled {
    background-color: $white;
  }
}
