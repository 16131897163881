#flow {
  padding-left: 6vw;
  padding-right: 6vw;
  .midashi1 {
  }
  .comment {
    font-size: 16px;
    margin-bottom: 20px;
    @include media-breakpoint-up(sm) {
      text-align: center;
      margin-bottom: 40px;
    }
  }
  .bg-gray {
    padding: 50px 6vw;
    @include media-breakpoint-up(sm) {
      padding: 80px 6vw;
    }
    .img {
      margin-bottom: 30px;
      @include media-breakpoint-up(lg) {
        margin: 0;
      }
      img {
      }
    }
    .cont {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 20px;
      @include media-breakpoint-up(sm) {
        flex: 1;
        justify-content: space-between;
        height: 100%;
      }
      @include media-breakpoint-up(lg) {
        display: none;
      }
      .step {
        .number {
          font-size: 10px;
          margin: 0;
          color: #446600;
          font-weight: bold;
          font-family: DIN Alternate;
          span {
            font-size: 20px;
          }
        }
        .text {
          margin: 0;
        }
      }
    }
  }
}
